import { Document } from '@contentful/rich-text-types';
import { UserGiveawayStatus } from '@/client/graphql/types.generated';
import { Cta } from '@meritcircle/ui/types';
import { GiveawayPageFragment } from '@cms/graphql/rewardsPage.generated';

export interface UserRewardImage {
  src: string;
  alt: string;
}

export interface UserReward {
  level: number;
  label: string;
  title: string;
  endAt?: string;
  millisecondsUntilEnd?: number,
  url: string;
  image: UserRewardImage;
}

export interface UserRewardsLevel {
  level: number;
  title: string;
  minimumXP: number;
  maximumXP: number;
  rewards: UserReward[];
}

export enum RewardScales {
  Common = 'common',
  Uncommon = 'uncommon',
  Rare = 'rare',
  UltraRare = 'ultra-rare',
  Unique = 'unique',
}

export enum RewardsPageStatus {
  Closed = 'CLOSED',
}

export type RewardsDetailPageStatus = RewardsPageStatus | UserGiveawayStatus;

export interface RewardsDetailPageProps {
  reward: GiveawayPageFragment;
  slug: string;
  lastUpdate: string;
}

export interface RewardsDetailPageTitleProps {
  title: string;
  badges: string[];
  pageStatus: RewardsDetailPageStatus;
  setPageStatus: (status: RewardsDetailPageStatus) => void;
  endAt?: string;
}

export interface FormatRewardDetailNextStep {
  title: string;
  description: Document;
}

export interface FormatGiveAwayStatus {
  description: Document;
  cta?: Cta;
}

export interface CountdownProps {
  startAt: string;
  endAt: string;
}
