import { URLS } from '@/config/urls';
import { Button, Flex, Link } from '@chakra-ui/react';
import { FullWidthImage, IconName, TitleSection } from '@meritcircle/ui/components';
import useTranslation from 'next-translate/useTranslation';
import NextLink from 'next/link';
import React from 'react';
import { RewardsSectionProps } from './rewards-section.types';
import { TextLink } from '@meritcircle/ui/components/text-link/text-link';
import { PageTitle } from '@/components/features/rewards/page-title/page-title';
import { useRewardQuery } from '@/client/graphql/rewards.generated';
import { rem } from 'polished';

export const RewardsSection = ({
  giveawaySlug,
  title,
  cta,
  backgroundImage,
}: RewardsSectionProps) => {
  const { t } = useTranslation('dashboard');

  const [{ data: giveawayData }] = useRewardQuery({
    variables: { slug: giveawaySlug },
  });

  const rewards = giveawayData?.giveaway;

  const categories = rewards?.minimumLevel.toString();
  const endDate = rewards?.endAt ? new Date(rewards.endAt) : null;
  const millisecondsUntilEnd = rewards?.millisecondsUntilEnd;

  return (
    <TitleSection
      css={{ marginTop: '7.5rem' }}
      title={t('rewards.title')}
      actionSlot={
        <TextLink href={URLS.REWARDS} rightIcon={{ icon: IconName.arrowRightFill }}>
          {t('rewards.button-text')}
        </TextLink>
      }>
      <FullWidthImage backgroundImage={backgroundImage}>
        <Flex gap="space.16" flexDirection="column">
          <PageTitle
            title={title}
            titleSize="h1"
            titleLevel="h3"
            badges={[`${t('common:labels.level')} ${categories}`]}
            endAt={endDate ? endDate.toString() : null}
            millisecondsUntilEnd={millisecondsUntilEnd}
            py={0}
            w={{ base: 'unset', md: rem(560) }}
          />
          <NextLink href={cta.url} passHref legacyBehavior>
            <Button variant="primary" as={Link} w="min" h="fit-content" py="space.16" mt="space.16">
              {cta.label}
            </Button>
          </NextLink>
        </Flex>
      </FullWidthImage>
    </TitleSection>
  );
};
