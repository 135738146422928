import { useCountdown } from '@/utils/hooks/useCountDown';
import { Flex, Text } from '@chakra-ui/react';
import { BlurFlex } from '@meritcircle/ui/components';
import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';
import { useEffect } from 'react';
import { CountdownComponentProps } from './countdown.types';

export const Countdown = ({ millisecondsUntilEnd, onCountdownEnd }: CountdownComponentProps) => {
  const { t } = useTranslation('common');

  const { countdown, hasExpired } = useCountdown(millisecondsUntilEnd);

  useEffect(() => {
    hasExpired && onCountdownEnd();
  }, [hasExpired, onCountdownEnd]);

  return (
    <Flex gap="space.16" mb="space.16">
      <BlurFlex px="space.16" py="space.8" gap="space.8">
        <Text as="time" pt="1px" fontSize={rem(24)} lineHeight={1}>
          {countdown.days}
        </Text>
        <Text size="xs" pt="1px" color="white" textTransform="uppercase" lineHeight={1}>
          {t('date.days')}
        </Text>
      </BlurFlex>
      <BlurFlex>
        <Text fontSize={rem(24)} pt="1px" lineHeight={1}>
          {`${countdown.hours}:${countdown.minutes}:${countdown.seconds}`}
        </Text>
      </BlurFlex>
    </Flex>
  );
};
