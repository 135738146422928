import { useEffect, useState } from 'react';
import { CountdownDiff, formatCountdown } from '../date';

export interface UseCountdown {
  hasExpired: boolean;
  countdown: CountdownDiff;
}

export const useCountdown = (millisecondsUntilEnd = 0): UseCountdown => {

  const [countdownInMilliseconds, setCountdownInMilliseconds] = useState(millisecondsUntilEnd);
  const [hasExpired, setHasExpired] = useState(countdownInMilliseconds <= 0);

  useEffect(() => {
    if (hasExpired) return;
    const interval = setInterval(() => {

      if (countdownInMilliseconds <= 0) {
        clearInterval(interval);
        setCountdownInMilliseconds(countdownInMilliseconds - 1000);
        setHasExpired(true);
        return;
      }

      setCountdownInMilliseconds(countdownInMilliseconds - 1000);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [countdownInMilliseconds, hasExpired]);

  // formatted countdown in days, hours, minutes, seconds
  const countdown = formatCountdown(countdownInMilliseconds)

  return { hasExpired, countdown };
};
