import { UserGiveawayStatus } from '@/client/graphql/types.generated';
import { Badge, Box, Flex, Heading, Text, chakra } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { Countdown } from '../countdown/countdown';
import { RewardsDetailPageStatus, RewardsPageStatus } from '../rewards.types';
import { PageTitleProps } from './page-title.types';
import { format } from 'date-fns';

const getStatusByQuery = (query: string): RewardsDetailPageStatus => {
  switch (query) {
    case 'won':
      return UserGiveawayStatus.Won;
    case 'lost':
      return UserGiveawayStatus.Lost;
    case 'joined':
      return UserGiveawayStatus.Participated;
    case 'closed':
      return RewardsPageStatus.Closed;
    default:
      return UserGiveawayStatus.NotParticipated;
  }
};

export const PageTitle = ({
  title,
  badges,
  endAt,
  millisecondsUntilEnd,
  pageStatus,
  setPageStatus,
  titleLevel = 'h1',
  titleSize = 'display',
  ...rest
}: PageTitleProps) => {
  const router = useRouter();
  const { t } = useTranslation('common');
  const endDate = endAt ? new Date(endAt) : new Date();

  useEffect(() => {
    if (router.query.status) {
      setPageStatus(getStatusByQuery(router.query.status as string));
      return;
    }
  }, [router.query.status, setPageStatus]);

  const onCountdownEnd = useCallback(() => {
    if (
      pageStatus &&
      pageStatus !== UserGiveawayStatus.Won &&
      pageStatus !== UserGiveawayStatus.Lost
    ) {
      setPageStatus(RewardsPageStatus.Closed);
      return;
    }
  }, [pageStatus, setPageStatus]);

  return (
    <Box {...rest} w="100%">
      <Flex gap={1} mb="space.16">
        {badges.map((badge, index) => (
          <Badge key={index} lineHeight={1}>
            {badge}
          </Badge>
        ))}
      </Flex>
      <Heading as={titleLevel} size={titleSize} variant="glowing" color="lightPurple" mb="space.32">
        {title}
      </Heading>
      {!!endAt && (
        <>
          <Countdown millisecondsUntilEnd={millisecondsUntilEnd} onCountdownEnd={onCountdownEnd} />
          <Text size="sm" fontWeight="400" variant="muted">
            {t('labels.raffle-ending')}:{' '}
            <chakra.time display="inline-block" dateTime={format(endDate, 'yyyy-MM-dd')}>
              {format(endDate, 'EEEE LLL do yyyy HH:mm zz')}
            </chakra.time>
          </Text>
        </>
      )}
    </Box>
  );
};
